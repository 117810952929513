import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import Home from './Home';
import Pricing from './Pricing';
import Devices from './Devices';
import Talkgroups from './Talkgroups';
import Company from './Company';
import Features from './Features';
import Downloads from './Downloads';
import Legal from './Legal';
import MobileStoreButton from 'react-mobile-store-button';
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      {new Date().getFullYear()}
      {' '}
      <a href="https:/www.writtenhouse.net">Writtenhouse Technology</a>


    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: '#B0C4DE',
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'System Access',
    subheader: 'Connect your radio for free',
    price: '0',
    description: ['Unlimited Public PTT', 
                  '999 Shared Groups', 
                  'Reserved Group Capable'],
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Private Talk Group',
    subheader: 'Run your own private channel',
    price: '15',
    description: ['Unlimited PTT on Channel',
                  'Channel Access Controls', 
                  'Reserved Group Capable'],

    buttonText: 'Get started',
    buttonVariant: 'contained',
  },
  {
    title: 'Enterprise',
    price: '30',
    description: [
      '50 users included',
      '30 GB of storage',
      'Help center access',
      'Phone & email support',
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];


const footers = [
  {
    title: 'Company',
    //description: ['History', 'Contact us', 'Locations'],
    description: ['Contact us'],
  },
  {
    title: 'Features',
    description: [ 
      'Talkgroup Based','E2E Encryption','LMR Gateway'],
  },
  {
    title: 'Resources',
    description: ['User Guide', 'Frequent Questions', 'Support Portal'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

export default function Wrapper() {
  const classes = useStyles();
  const playStoreUrl = 'https://play.google.com/store/apps/details?id=net.writtenhouse.android.pttclient';
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
          <Link variant="button" color="textPrimary" href="/" className={classes.toolbarTitle}>
              FreePTT Network Radio
            </Link>
          </Typography>

          <nav>
          <Link variant="button" color="textPrimary" href="/features" className={classes.link}>
              Features
            </Link>
{/*
            <Link variant="button" color="textPrimary" href="/pricing" className={classes.link}>
              Pricing
            </Link>
*/}
            <Link variant="button" color="textPrimary" href="/devices" className={classes.link}>
              Devices
            </Link>
            <Link variant="button" color="textPrimary" href="/talkgroups" className={classes.link}>
              Talkgroups
            </Link>


          </nav>

{/*
          <Button href="#" color="primary" variant="outlined" className={classes.link}>
            Login
          </Button>
*/}
        </Toolbar>
      </AppBar>





      <Router>
        <Switch>               
            <Route exact path="/" component={Home}/>
            <Route exact path="/pricing" component={Pricing}/>
            <Route exact path="/devices" component={Devices}/>
            <Route exact path="/talkgroups" component={Talkgroups}/>
            <Route exact path="/company" component={Company}/>
            <Route exact path="/features" component={Features}/>
            <Route exact path="/downloads" component={Downloads}/>
            <Route exact path="/legal" component={Legal}/>

        </Switch>
    </Router>

      <Typography variant="body2" color="textSecondary" align="center">
        <MobileStoreButton
                    store="android"
                    url={playStoreUrl}
                    linkProps={{ title: 'Google Play Store Button' }}
                  />
      </Typography>
        


      {/* Footer */}
      <Container maxWidth="md" component="footer" className={classes.footer}>
        <Grid container spacing={4} justifyContent="space-evenly">
          {footers.map((footer) => (
            <Grid item xs={6} sm={3} key={footer.title}>
              <Typography variant="h6" color="textPrimary" gutterBottom>
                {footer.title}
              </Typography>
              <ul>
                {footer.description.map((item) => (
                  <li key={item}>
                    <Link href={`/${footer.title.toLowerCase()}#${item.toLowerCase()}`}  variant="subtitle1" color="textSecondary">
                      {item}
                    </Link>
                  </li>
                ))}
              </ul>
            </Grid>
          ))}
        </Grid>
        <Box mt={5}>

          <Copyright />
        </Box>
      </Container>
      {/* End footer */}
    </React.Fragment>
  );
}