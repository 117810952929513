import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';



const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

const tiers = [
  {
    title: 'Network Radio 0.11.0',
    subheader: ' This app generates a unique UUID when first installed and passes this to the network for authentication. This app collects audio from the microphone when the user is in a walkie talkie call and sends it to the intended target via the server. This app does not collect any personally identifiable information. This app uses the following android permissions:',
    price: '0',
    description: ['android.permission.INTERNET - This permission is used to connect to the PTT network',
    'android.permission.CAMERA -  This permission is used by the QR Code scanner only when accessed by the user.',
    'android.permission.VIBRATE -  This permission is used to send vibration alerts.',
    'android.permission.RECORD_AUDIO - This permission is used to collect audio from the speaker when in a walkie talkie call and send the the users intended target.',
    'android.permission.MODIFY_AUDIO_SETTINGS -  This permission is used to adjust the audio volume from the buttons within the application ',
    'android.permission.ACCESS_NETWORK_STATE - This permission is used to determine the connection type in order to select the voice compression',
    'android.permission.FOREGROUND_SERVICE - This permission is used to keep a persistant connection to the PTT network server',
    'android.permission.WAKE_LOCK- This permission is used to keep a persistant connection to the PTT network server',
    ''],
    buttonText: '',
    buttonVariant: 'outlined',
  },

];
const footers = [
  {
    title: 'Company',
    description: ['Team', 'History', 'Contact us', 'Locations'],
  },
  {
    title: 'Features',
    description: ['LMR Gateway', 
                  'Voice Encryption', 
                  'Dedicated Talk Groups', 
                  'Private Call', 
                  'PBX Service'],
  },
  {
    title: 'Resources',
    description: ['Resource', 'Resource name', 'Another resource', 'Final resource'],
  },
  {
    title: 'Legal',
    description: ['Privacy policy', 'Terms of use'],
  },
];

export default function Devices() {
  const classes = useStyles();

  return (
    <React.Fragment>
   
   


      
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent}>
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Privacy Policy
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" component="p">

        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main">
        <Grid container spacing={5} alignItems="flex-end">


          
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={12}>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Pro' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent>

                  <ul>
                    {tier.description.map((line) => (
                      <Typography component="li" variant="subtitle1" align="left" key={line}>
                        {line}
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>




    </React.Fragment>
  );
}